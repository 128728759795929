// @primary-color: #1890ff;
// @import '~antd/dist/antd.less';
// #root{
//   zoom: 0.9;
// }
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  background-color: #9e9e9e;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  border-radius: 5px;
  background-color: #f9f9f9;
}


.pro-upload-picture-box {

  .ant-tooltip-placement-top,
  .ant-tooltip-placement-bottom {
    display: none !important;
  }

  .ant-upload-list-item-error .ant-upload-list-item-name {
    color: #909399 !important;
  }

  .anticon svg path[fill='#e6f7ff'] {
    fill: #fff !important;
  }

  .anticon svg path[fill='#1890ff'] {
    fill: #909399 !important;
  }
}

.upload-ProTable-box {
  overflow-x: auto;

  .ant-card-body {
    padding: 20px 0;
  }
}

.upload-ProTable-box::-webkit-scrollbar-thumb {
  display: block;
}

.upload-ProTable-box::-webkit-scrollbar {
  height: 10px;
}

.upload-ProTable-box::-webkit-scrollbar-track {
  background: rgb(222, 222, 222);
}

.international-select {
  margin-top: 30px !important;

  .ant-select-selector {
    border-left: none !important;
  }
}

.ant-pro-form-list-item {
  .ant-form-item-label {
    display: block !important;
  }
}
.rulesTableP{
  display: flex;
  justify-content: center;
  margin: 5px 0;
  line-height: 30px;
  span{
    width: 50%;
    text-align: center;
    color: #333;
    border-bottom: 2px dashed #eeeeee;
  }
  .t{
    color: #000;
    font-weight: bold;
    border: none;
  }
}
